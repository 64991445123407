.step-5-loader-container {
    width: 350px;
    height: auto;
    background-color: white;
    border-radius: 5px;
    display: grid;
    grid-template-rows: 100px 60px;
    .loader {
      height: 32px;
      top: 0%;
      left: 0%;
      display: grid;
      justify-self: center;
      justify-items: center;
      align-self: center;
      align-items: center;
      .ball-pulse-sync div {
            height: 15px;
            width: 15px;     
      }
      .ball-pulse-sync div:nth-child(1) {
        border-color: #2b76b9 !important;
        background-color:#2b76b9;      
      }
      .ball-pulse-sync div:nth-child(2) {
        border-color: #2cacd1 !important;
        background-color: #2cacd1;     
      }
      .ball-pulse-sync div:nth-child(3) {
        border-color:#1fbfa4 !important;
        background-color:#1fbfa4;       
      }
    }
    .step-5-loader-text {
        display: grid;
        height: 50px;
        user-select: none;
        align-self: center;
        justify-self: center;
        position: relative;
        width: 300px;
        color: #2b76b9;
        text-align: center;
    }
}


#step-2 {
  width: 50%;
  height: auto;
  background: white;
  border-radius: 6px;
  animation: enter-bottom 0.3s ease-in-out forwards;

  .modal-title {
    user-select: none;
    display: grid;
    justify-content: center;
    align-content: center;
    width: 100%;
    color: #2b76b9;
    height: 2.8rem;
    font-size: 1.3rem;
    text-align: center;
  }

  .date-text {
    color: #ababab;
    /* float: right; */
    /* width: 100%; */
    /* position: absolute; */
    /* right: 0px; */
    /* height: 2.8rem; */
    /* margin-right: 2%; */
    padding-right: 2%;
    display: grid;
    align-content: center;
    justify-content: right;
    border-bottom: solid 1px lightgray;
  }

  .form-container {
    display: block;
  }
  .form-container::-webkit-scrollbar-track {
    background-color: #575656bd;
  }

  .form-container::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  .form-container::-webkit-scrollbar-thumb {
    background-color: rgb(183, 213, 238);
  }

  .form-container::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0);
  }

  form {
    margin-top: 10px;
  }

  #rpm-input {
    margin-bottom: 0;
  }

  .submit-btn-container {
    position: absolute;
    display: grid;
    justify-content: flex-end;
    align-content: center;
    margin: 0 5% 10px 5%;
    right: 0;
    bottom: 0;

    .next-button {
      display: block;
      border: none;
      font-family: Maven-Regular;
      height: 30px;
      width: 80px;
      text-decoration: none;

      outline: none;
      color: white;
      background-size: 200% auto;
      border-radius: 5px;
      background-image: linear-gradient(
        60deg,
        #3d3393 0%,
        #2b76b9 25%,
        #2cacd1 55%,
        #1fbfa4 100%
      );
      transition: all 0.5s;
    }
    .next-button:hover {
      cursor: pointer;
      background-position: 40% 0;
    }
  }
}

@keyframes enter-bottom {
  from {
    transform: translateY(600px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

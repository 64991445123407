.clone-modal {
  padding-top: 8px;
  position: absolute;
  background-color: rgb(253, 253, 255);
  border: solid 1px rgb(165, 194, 194);
  border-radius: 5px;
  left: 365px;
  margin-left: 45px;
  width: 450px;
  height: 250px;
  top: 20%;
  z-index: 2;
  box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px,
    rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px;
  display: grid;
  grid-template-rows: 25% 40% 35%;
  .clone-modal-title {
    text-align: center;
    width: 80%;
    height: 50%;
    align-self: center;
    justify-self: center;
  }
  .config-dropdown {
    position: relative;
    width: 80%;
    display: grid;
    align-self: center;
    justify-self: center;
    .select-custom-cont {
      .MuiInputBase-root {
        width: 100%;
        font-family: Maven-Regular;
      }
    }
  }
  .clone-modal-choice {
    position: relative;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    justify-items: center;
    .clone-modal-confirm {
      width: 80px;
      height: 35px;
      display: grid;
      background-color: #2354d2;
      align-items: center;
      justify-items: center;
      border-radius: 5px;
      cursor: pointer;
      color: white;
    }
    .clone-modal-cancel {
      width: 80px;
      height: 35px;
      display: grid;
      background-color: #e93d3d;
      align-items: center;
      justify-items: center;
      border-radius: 5px;
      cursor: pointer;
      color: white;
    }
  }
}

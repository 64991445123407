.img-container {
  margin: 10px 10px;
}
.img-result {
  width: 100%;
  max-width: 100%;
}

// .Activated_content__2QBG5 {
//   img {
//     max-width: 100%;
//   }
// }

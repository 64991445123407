.modals-area {
  position: relative;
  width: 80%;
  height: 100%;
  display: grid;
  grid-template-rows: 5vh 90vh 5vh;
  align-items: center;
  justify-items: center;
  box-sizing: border-box;
  background: #ffffff;
  background-image: linear-gradient(
    60deg,
    #3d3393 0%,
    #2b76b9 37%,
    #2cacd1 65%,
    #1fbfa4 100%
  );
  .modals-area-top {
    width: 100%;
    height: 5vh;
    display: grid;
    grid-template-columns: auto 50px 50px 50px;
  }

  // CSS for sizing of input fields
  .MuiFormLabel-root {
    font-family: Maven-Regular;
  }

  .MuiInputLabel-outlined[data-shrink='false'] {
    z-index: 1;
    transform: translate(15px, 12px) scale(1);
    pointer-events: none;
  }

  .MuiInputBase-input {
    padding: 9px 0 10px 10px;
  }

  .MuiOutlinedInput-multiline {
    padding: 6px 0 7px;
  }

  .input-field {
    font-family: Overpass-Regular;
    width: 90%;
    margin: 10px 5%;
    font-size: 14px;
  }

  .input-field-lambert {
    font-family: Overpass-Regular;
    width: 40%;
    margin: 10px 5%;
    font-size: 14px;
  }

  .modals-area-bottom {
    width: 100%;
    height: 5vh;
    display: grid;
    grid-template-columns: auto 50px;
    #copyright-tag {
      user-select: none;
      color: white;
      font-size: 0.6rem;
      align-self: flex-end;
      justify-self: center;
      position: relative;
      left: 50px;
      margin-bottom: 5px;
    }
    #version-name {
      user-select: none;
      color: white;
      font-size: 0.6rem;
      align-self: flex-end;
      justify-self: center;
      margin-bottom: 5px;
    }
  }
}

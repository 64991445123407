.sidebar-context-menu {
  position: absolute;
  display: grid;
  width: 120px;
  height: auto;
  background-color: white;
  border-radius: 2px;
  padding: 10px 0;
  box-shadow: rgba(15, 15, 15, 0.05) 0 0 0 1px,
    rgba(15, 15, 15, 0.1) 0 3px 6px, rgba(15, 15, 15, 0.2) 0 9px 24px;
  .menu-item {
    display: grid;
    grid-template-columns: 20px auto;
    transition: background-color 0.2s;
    padding: 0 10px;
    height: 25px;
    .delete-icon,
    .rename-icon,
    .clone-icon {
      user-select: none;
      width: 17px;
      align-self: center;
      justify-self: center;
    }
    .delete-text,
    .rename-text,
    .clone-text {
      user-select: none;
      color: #515050;
      align-self: center;
      justify-self: flex-start;
      padding-left: 10px;
      font-size: 1rem;
    }
  }
  .menu-item:hover {
    background-color: rgb(216, 216, 216);
    cursor: pointer;
  }
}

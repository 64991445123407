.rename-modal {
  padding-top: 8px;
  position: absolute;
  background-color: rgb(253, 253, 255);
  border: solid 1px rgb(165, 194, 194);
  border-radius: 5px;
  left: 365px;
  margin-left: 45px;
  width: 400px;
  height: auto;
  top: 20%;
  z-index: 2;
  box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px,
    rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px;
  display: grid;
  .rename-modal-title {
    text-align: center;
    width: 80%;
    align-self: center;
    justify-self: center;
  }
  .MuiFormLabel-root {
    font-family: Maven-Regular;
  }

  .MuiInputLabel-outlined[data-shrink='false'] {
    z-index: 1;
    transform: translate(15px, 12px) scale(1);
    pointer-events: none;
  }

  .MuiInputBase-input {
    padding: 9px 0px 10px 10px;
  }

  .input-field {
    font-family: Overpass-Regular;
    width: 90%;
    margin: 10px 5%;
    font-size: 14px;
  }
  .rename-modal-choice {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    justify-items: center;
    margin: 10px 0px 30px 0px;
    .rename-modal-confirm {
      width: 80px;
      height: 35px;
      display: grid;
      background-color: #2354d2;
      align-items: center;
      justify-items: center;
      border-radius: 5px;
      cursor: pointer;
      color: white;
    }
    .rename-modal-cancel {
      width: 80px;
      height: 35px;
      display: grid;
      background-color: #e93d3d;
      align-items: center;
      justify-items: center;
      border-radius: 5px;
      cursor: pointer;
      color: white;
    }
  }
}

@font-face {
  font-family: Assistant-Light;
  src: url(../src/assets/fonts/Assistant/Assistant-Light.ttf);
}
@font-face {
  font-family: Overpass-Regular;
  src: url(../src/assets/fonts/Overpass/Overpass-Regular.ttf);
}
@font-face {
  font-family: Overpass-Light;
  src: url(../src/assets/fonts/Overpass/Overpass-Light.ttf);
}
@font-face {
  font-family: Cairo-Regular;
  src: url(../src/assets/fonts/Cairo/Cairo-Regular.ttf);
}
@font-face {
  font-family: Cairo-Light;
  src: url(../src/assets/fonts/Cairo/Cairo-Light.ttf);
}
@font-face {
  font-family: Hind-Regular;
  src: url(../src/assets/fonts/Hind_Siliguri/HindSiliguri-Regular.ttf);
}
@font-face {
  font-family: Hind-Light;
  src: url(../src/assets/fonts/Hind_Siliguri/HindSiliguri-Light.ttf);
}
@font-face {
  font-family: Maven-Medium;
  src: url(../src/assets/fonts/Maven_Pro/static/MavenPro-Medium.ttf);
}
@font-face {
  font-family: Maven-Regular;
  src: url(../src/assets/fonts/Maven_Pro/static/MavenPro-Regular.ttf);
}

html,
body,
#root,
.App {
  position: relative;
  font-family: Maven-Regular;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;

  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
  }

  #gray-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(114, 114, 114, 0.692);
    z-index: 10;
  }
  
  .loader-hidden {
    display: none;
  }

  .loader-container {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 3;
    display: none;
    .loader {
      height: 32px;
      top: 0%;
      left: 0%;
      display: grid;
      justify-self: center;
      justify-items: center;
      align-self: center;
      align-items: center;
      .ball-pulse-sync div {
            height: 15px;
            width: 15px;     
      }
      .ball-pulse-sync div:nth-child(1) {
        border-color: #2b76b9 !important;
        background-color:#2b76b9;      
      }
      .ball-pulse-sync div:nth-child(2) {
        border-color: #2cacd1 !important;
        background-color: #2cacd1;     
      }
      .ball-pulse-sync div:nth-child(3) {
        border-color:#1fbfa4 !important;
        background-color:#1fbfa4;       
      }
    }
  }
}

.step-1b-container {
  width: 100%;
  height: 90vh;
  align-self: center;
  display: grid;
  overflow-y: hidden;

  #delete-modal-1b {
    display: none;
    position: absolute;
    background-color: rgb(253, 253, 255);
    border: solid 1px rgb(165, 194, 194);
    border-radius: 5px;
    left: 50%;
    top: 50%;
    width: 400px;
    height: 200px;
    z-index: 3;
    grid-template-rows: 50% 50%;
    transform: translate(0px, -50%);

    #delete-modal-1b-text {
      width: 80%;
      height: 50%;
      align-self: center;
      justify-self: center;
    }
    #delete-modal-1b-buttons {
      position: relative;
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: 50% 50%;
      align-items: center;
      justify-items: center;
      #delete-modal-1b-proceed {
        width: 80px;
        height: 35px;
        display: grid;
        background-color: #6bdc6b;
        align-items: center;
        justify-items: center;
        border-radius: 5px;
        cursor: pointer;
        color: white;
      }
      #delete-modal-1b-cancel {
        width: 80px;
        height: 35px;
        display: grid;
        background-color: #f96262;
        align-items: center;
        justify-items: center;
        border-radius: 5px;
        cursor: pointer;
        color: white;
      }
    }
  }

  #step-1b {
    width: 90%;
    height: 90vh;
    display: grid;
    margin: 0 auto;
    background: white;
    border-radius: 6px;
    // box-shadow: 4px 6px 10px 1px #808282;
    animation: enter-bottom 0.3s ease-in-out forwards;

    .modal-title {
      user-select: none;
      display: grid;
      justify-content: center;
      align-content: center;
      width: 100%;
      color: #2b76b9;
      font-size: 1.3rem;
      text-align: center;
    }

    .date-text {
      color: #ababab;
      padding-right: 2%;
      display: grid;
      align-content: center;
      justify-content: right;
      border-bottom: solid 1px lightgray;
    }

    .form-container {
      display: block;
      overflow-y: auto;
      form {
        margin-top: 15px;
        overflow-y: hidden;
        overflow-x: hidden;
        #tog-cont {
          align-self: center;
          position: relative;
          width: 60px;
          margin-left: -20px;
          display: inline-block;
          margin-right: 20px;
          #toggle-lambert.off {
            position: relative;
            display: grid;
            left: 20px;
            align-content: center;
            justify-content: center;
            height: 30px;
            font-size: 0.8rem;
            border-radius: 15px;
            color: white;
            background-color: #2354d2;
            transition: all 0.5s;
            -webkit-user-select: none;
            user-select: none;
            #coordinates-belgium {
              position: absolute;
              top: 8px;
              left: 7px;
              font-size: 0.7rem;
            }
            #coordinates-world {
              position: absolute;
              top: 8px;
              right: 8px;
              font-size: 0.7rem;
            }
            #slider {
              position: relative;
              right: -15px;
              width: 30px;
              height: 30px;
              background-color: #fafafa;
              border-radius: 15px;
              transition: all 0.5s;
            }
          }
          #toggle-lambert.on {
            left: 20px;
            position: relative;
            display: grid;
            align-content: center;
            justify-content: center;
            width: 60px;
            height: 30px;
            font-size: 0.8rem;
            border-radius: 15px;
            color: white;
            background-color: #8098d3;
            transition: all 0.5s;
            -webkit-user-select: none;
            user-select: none;
            #coordinates-belgium {
              position: absolute;
              top: 8px;
              left: 7px;
              font-size: 0.7rem;
            }
            #coordinates-world {
              position: absolute;
              top: 8px;
              right: 8px;
              font-size: 0.7rem;
            }
            #slider {
              position: relative;
              right: 15px;
              width: 30px;
              height: 30px;
              background-color: #fafafa;
              border-radius: 15px;
              transition: all 0.5s;
            }
          }
          #toggle-lambert:hover {
            cursor: pointer;
          }
          #toggle-lambert:active {
            cursor: pointer;
          }
          #toggle-text {
            text-align: center;
            position: relative;

            font-size: 0.7rem;
            width: 100px;
            color: white;
          }
        }

        #aoi-second-row-container {
          display: grid;
          grid-template-columns: 80px 160px 160px 120px auto;
          height: 38px;
          margin: 10px 5%;
          .input-field-lambert-x {
            .MuiInputBase-formControl {
              width: 150px;
            }
          }
          .input-field-lambert-y {
            .MuiInputBase-formControl {
              width: 150px;
            }
          }
          #try-button {
            display: grid;
            justify-content: center;
            align-content: center;
            #try-coords {
              display: inline-block;
              border: none;
              font-family: Maven-Regular;
              height: 30px;
              width: 100px;
              text-decoration: none;
              outline: none;
              color: white;
              background-size: 200% auto;
              border-radius: 5px;
              background-image: linear-gradient(
                60deg,
                #3d3393 0%,
                #2b76b9 25%,
                #2cacd1 55%,
                #1fbfa4 100%
              );
              transition: all 0.5s;
            }
            #try-coords:disabled {
              background-color: #f8f8f8;
              color: rgba(0, 0, 0, 0.87);
              background-image: none;
            }
            #try-coords:hover {
              cursor: pointer;
              background-position: 40% 0;
            }
          }

          .submit-btn-container {
            display: grid;
            justify-content: flex-end;
            align-content: center;

            .next-button {
              display: block;
              border: none;
              font-family: Maven-Regular;
              height: 30px;
              width: 80px;
              text-decoration: none;
              outline: none;
              color: white;
              background-size: 200% auto;
              border-radius: 5px;
              background-image: linear-gradient(
                60deg,
                #3d3393 0%,
                #2b76b9 25%,
                #2cacd1 55%,
                #1fbfa4 100%
              );
              transition: all 0.5s;
            }
            .next-button:hover {
              cursor: pointer;
              background-position: 40% 0;
            }
          }
        }

        #map-be,
        #map-world {
          display: block;
          width: 96%;
          height: auto;
          padding: 0;
          margin: 0 2% 10px 2%;
          .leaflet-container {
            border-radius: 6px;
            height: 65vh;
            width: 100%;
          }
        }
      }
      select {
        outline: none;
        font-family: Overpass-Regular;
        width: 90%;
        margin: 10px auto;
        height: 38px;
        border-radius: 4px;
        left: 5%;
        position: relative;
        padding: 10px 10px;
        border: 1px solid #bdbdbd;
      }
      p {
        margin: 5px auto 0 auto;
        width: 90%;
      }
      input.text-own {
        font-family: Overpass-Regular;
        display: block;
        box-sizing: border-box;
        width: 90%;
        margin: 10px auto;
        border-radius: 4px;
        border: 1px solid #bdbdbd;
        padding: 10px 10px;
        font-size: 14px;
        outline: none;
      }
      input.radio-own {
        display: inline-block;
        width: 6%;
      }
    }
    .form-container::-webkit-scrollbar-track {
      background-color: #ffffff;
      border-bottom-right-radius: 6px;
    }

    .form-container::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    .form-container::-webkit-scrollbar-thumb {
      border-right: solid 1px white;
      border-left: solid 1px white;
      background-image: linear-gradient(
        0deg,
        #2b76b9 50%,
        #2cacd1 75%,
        #1fbfa4 100%
      );
      border-radius: 6px;
    }

    .form-container::-webkit-scrollbar-corner {
      background: rgba(0, 0, 0, 0);
    }
  }
}

@keyframes enter-bottom {
  from {
    transform: translateY(600px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

.top-left-clipped {
  cursor: pointer;
  position: absolute;
  display: grid;
  grid-template-columns: 90% 10%;
  padding: 5px 10px 5px 10px;
  width: 190px;
  height: 35px;
  left: 20px;
  top: 20px;
  background-color:  #2355d2;
  border-radius: 5px;
  align-self: center;
  justify-self: center;
  user-select: none;
  color: white;
  text-decoration: none;
  transition: box-shadow ease-in-out 0.3s;
  animation: enter-right 1.5s forwards;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  z-index: 2;
  #home-icon {
    width: 18px;
    align-self: center;
  }
  .website-text {
    align-self: center;
  }
}

.top-left-clipped:hover {
  cursor: pointer;
  box-shadow: inset 210px 0 1px 1px #3567e6;
  color: white
}



.auth {
  width: 100%;
  height: 100%;
  background-color: #b5b5b51f;
  display: grid;
  justify-content: center;
  align-content: center;

  .auth-form-container-login,
  .auth-form-container-new-password,
  .auth-form-container-reset-password,
  .auth-form-container-reset-password-2
  {
    border: solid 1px rgb(214, 214, 214);
    border-radius: 10px;
    max-width: 500px;
    min-width: 420px;
    padding: 40px 20px 10px 20px;
    background: white;
    box-shadow: 0px 0px 11px 0px #80808069;

    .new-password-text {
      margin-left: 20px;
      margin-bottom: 10px;
      color: #808080;
    }

    .auth-title-container {
      position: relative;
      bottom: 40px;
      font-family: Overpass-Regular;
      display: grid;
      align-items: center;
      justify-items: center;
      width: 100%;
      height: 100px;
      margin: 0 auto;

      .tricer-logo {
        width: 200px;
      }
    }
     // CSS for sizing of input fields
     .MuiFormLabel-root {
      font-family: Maven-Regular;
     }
    .MuiInputLabel-outlined[data-shrink="false"] {
      z-index: 1;
      transform: translate(15px, 12px) scale(1);
      pointer-events: none;
    }

    .MuiInputBase-input {
      font-family: Maven-Regular;
      padding: 9px 0px 10px 10px;
    }

    .input-field {
      width: 90%;
      margin: 10px 5%;
      font-size: 14px;
    }

    .btn-container {
      height: 50px;
      width: 100%;
      display: grid;
      justify-content: center;
      align-content: center;

      button[type="submit"] {
        font-size: 0.9rem;
        font-family: Maven-Regular;
        cursor: pointer;
        height: 40px;
        background: #2354d2;
        color: white;
        border: none;
        border-radius: 4px;
        width: 100px;
        padding: 10px;
        transition: all 0.1s;
        outline: none;
      }

      button[type="submit"]:hover {
        background: #2c64f1;
        transform: scale(1.05);
      }

      button[type="submit"]:active {
        transition: 0.1s all;
        transform: scale(0.7);
        border: 1px solid transparent;
        opacity: 0.8;
        outline: none;
      }
    }
  }

  #forgot-password, #back-to-login {
    margin-top: 20px;
    margin-bottom: 5px;
    color: grey;
    cursor: pointer;
  }

  #forgot-password:hover, #back-to-login:hover {
    color: black;
  }

  
}

.copyright {
  position: absolute;
  bottom: 0px;
  margin: 10px 10%;
  font-size: 0.6rem;
  text-align: center;
  color: lightslategrey;
  z-index: 4;
}

@keyframes enter-right {
  from {
    transform: translateX(300px);
    opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
}

.reactivation-modal {
    
    background-color: rgb(253, 253, 255);
    border: solid 1px rgb(165, 194, 194);
    border-radius: 5px;
    left: 365px;
    margin-left: 45px;
    width: 500px;
    height: 250px;
    top: 20%;
    z-index: 2;
    //   box-shadow: 2px 2px 9px 2px #d8d8d8;
    display: grid;
    grid-template-rows: 50% 50%;
    .reactivation-modal-title {
      width: 80%;
      height: 50%;
      align-self: center;
      justify-self: center;
    }
    .reactivation-modal-choice {
      position: relative;
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: 50% 50%;
      align-items: center;
      justify-items: center;
      .reactivation-modal-choice-yes {
        width: 60px;
        height: 35px;
        display: grid;
        background-color: #6bdc6b;
        align-items: center;
        justify-items: center;
        border-radius: 5px;
        cursor: pointer;
        color: white;
      }
      .reactivation-modal-choice-no {
        width: 60px;
        height: 35px;
        display: grid;
        background-color: #f96262;
        align-items: center;
        justify-items: center;
        border-radius: 5px;
        cursor: pointer;
        color: white;
      }
    }
  }
  
.step-4-container {
  position: relative;
  width: 100%;
  height: 90vh;
  align-self: center;
  display: grid;
  grid-template-rows: 65px calc(90vh - 65px);
  overflow-y: hidden;
  #submit-loader-div {
    position: absolute;
    height: 90vh;
    margin: 0 5%;
    width: 90%;
    background-color: #ffffffc2;
    z-index: 3;
    border-radius: 10px;
    display: grid;
    .loader {
      height: 32px;
      top: 0%;
      left: 0%;
      display: grid;
      justify-self: center;
      justify-items: center;
      align-items: center;
      align-self: center;
      .ball-pulse-sync div {
        height: 10px;
        width: 10px;
      }
      .ball-pulse-sync div:nth-child(1) {
        border-color: #2b76b9 !important;
        background-color: #2b76b9;
      }
      .ball-pulse-sync div:nth-child(2) {
        border-color: #2cacd1 !important;
        background-color: #2cacd1;
      }
      .ball-pulse-sync div:nth-child(3) {
        border-color: #1fbfa4 !important;
        background-color: #1fbfa4;
      }
    }
  }
  #toggle-cont-grid {
    #tog-cont {
      align-self: center;
      position: relative;
      left: 15%;
      width: 60px;
      margin: 0 30px 0 0;
      display: inline-block;
      #toggle-existing-results.off {
        position: relative;

        display: grid;
        left: 20px;
        align-content: center;
        justify-content: center;
        width: 60px;
        height: 30px;
        font-size: 0.8rem;
        border-radius: 15px;
        color: white;
        background-color: #8098d3;
        transition: all 0.5s;
        -webkit-user-select: none;
        user-select: none;
        #status-text-off {
          position: absolute;
          top: 8px;
          left: 7px;
          font-size: 0.7rem;
        }
        #status-text-on {
          position: absolute;
          top: 8px;
          right: 8px;
          font-size: 0.7rem;
        }
        #slider {
          position: relative;
          right: -15px;
          width: 30px;
          height: 30px;
          background-color: #fafafa;
          border-radius: 15px;
          transition: all 0.5s;
        }
      }
      #toggle-existing-results.on {
        left: 20px;
        position: relative;

        display: grid;
        align-content: center;
        justify-content: center;
        width: 60px;
        height: 30px;
        font-size: 0.8rem;
        border-radius: 15px;
        color: white;
        background-color: #2354d2;
        transition: all 0.5s;
        -webkit-user-select: none;
        user-select: none;
        #status-text-off {
          position: absolute;
          top: 8px;
          left: 7px;
          font-size: 0.7rem;
        }
        #status-text-on {
          position: absolute;
          top: 8px;
          right: 8px;
          font-size: 0.7rem;
        }
        #slider {
          position: relative;
          right: 15px;
          width: 30px;
          height: 30px;
          background-color: #fafafa;
          border-radius: 15px;
          transition: all 0.5s;
        }
      }
      #toggle-existing-results:hover {
        cursor: pointer;
      }
      #toggle-existing-results:active {
        cursor: pointer;
      }
      #toggle-text {
        text-align: center;
        position: relative;

        font-size: 0.7rem;
        width: 100px;
        color: white;
      }
    }

    #socind-tog-cont {
      position: relative;
      left: calc(15% + 60px);
      display: inline-block;
      width: 140px;
      margin: 0 auto;
      #toggle-societal-individual.off {
        position: relative;

        display: grid;
        align-content: center;
        justify-content: center;
        width: 140px;
        height: 30px;
        font-size: 0.8rem;
        border-radius: 15px;
        color: white;
        background-color: #2354d2;
        transition: all 0.5s;
        -webkit-user-select: none;
        user-select: none;
        #status-text-ind {
          position: absolute;
          top: 8px;
          left: 10px;
          font-size: 0.7rem;
        }
        #status-text-soc {
          position: absolute;
          top: 8px;
          right: 7px;
          font-size: 0.7rem;
        }
        #slider-socind {
          position: relative;
          right: -35px;
          width: 70px;
          height: 30px;
          background-color: #fafafa;
          border-radius: 15px;
          transition: all 0.5s;
        }
      }
      #toggle-societal-individual.on {
        position: relative;

        display: grid;
        align-content: center;
        justify-content: center;
        width: 140px;
        height: 30px;
        font-size: 0.8rem;
        border-radius: 15px;
        color: white;
        background-color: #2354d2;
        transition: all 0.5s;
        -webkit-user-select: none;
        user-select: none;
        #status-text-ind {
          position: absolute;
          top: 8px;
          left: 10px;
          font-size: 0.7rem;
        }
        #status-text-soc {
          position: absolute;
          top: 8px;
          right: 7px;
          font-size: 0.7rem;
        }
        #slider-socind {
          position: relative;
          right: 35px;
          width: 70px;
          height: 30px;
          background-color: #fafafa;
          border-radius: 15px;
          transition: all 0.5s;
        }
      }
      #toggle-existing-results:hover,
      #toggle-societal-individual:hover {
        cursor: pointer;
      }
      #toggle-existing-results:active,
      #toggle-societal-individual:active {
        cursor: pointer;
      }
      #toggle-text,
      #socind-text {
        text-align: center;
        position: relative;

        font-size: 0.7rem;
        width: 140px;
        color: white;
      }
    }
  }

  .results-container {
    display: grid;
    width: 95%;
    margin: 0 auto;
    background: white;
    overflow-y: auto;
    border-radius: 6px;
    background: linear-gradient(to top, #f7f7f7, #efefef);
    // box-shadow: 4px 6px 10px 1px #808282;
    animation: enter-bottom 0.3s ease-in-out forwards;

    .exception-message {
      height: auto;
      align-self: center;
      width: 80%;
      font-size: 1.2rem;
      justify-self: center;
    }
  }

  .results-container::-webkit-scrollbar-track {
    background-color: #ffffff;
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
  }

  .results-container::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  .results-container::-webkit-scrollbar-thumb {
    border-right: solid 1px white;
    border-left: solid 1px white;
    background-image: linear-gradient(
      0deg,
      #2b76b9 50%,
      #2cacd1 75%,
      #1fbfa4 100%
    );
    border-radius: 6px;
  }

  .results-container::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0);
  }

  #step-4 {
    width: 90%;
    height: calc(90vh - 65px);
    display: grid;
    margin: 0 auto;
    background: white;
    border-radius: 6px;
    // box-shadow: 4px 6px 10px 1px #808282;
    animation: enter-bottom 0.3s ease-in-out forwards;

    .modal-title {
      user-select: none;
      display: grid;
      justify-content: center;
      align-content: center;
      width: 100%;
      color: #2b76b9;
      height: 2.8rem;
      font-size: 1.3rem;
      text-align: center;
    }

    .date-text {
      color: #ababab;
      padding-right: 2%;
      display: grid;
      align-content: center;
      justify-content: right;
      border-bottom: solid 1px lightgray;
    }

    .leaflet-container {
      border-radius: 6px;
      width: 100%;
      height: 200px;
    }
    .form-container {
      display: block;

      overflow-y: auto;
      form {
        margin-top: 15px;
        overflow-y: hidden;
        overflow-x: hidden;
        #map-be,
        #map-world {
          display: block;
          width: 96%;
          padding: 0px;
          margin: 0px 2% 10px 2%;
          .leaflet-container {
            height: 60vh;
            width: 100%;
          }
        }
      }
      select {
        outline: none;
        font-family: Maven-Regular;
        font-size: 1rem;
        width: 90%;
        margin: 10px auto;
        height: 39px;
        border-radius: 4px;
        width: 90%;
        margin: 10px 5%;
        position: relative;
        padding: 8px 0px 9px 9px;
        border: 1px solid #bdbdbd;
        option {
          font-size: 1rem;
        }
      }
      p {
        margin: 5px auto 0px auto;
        width: 90%;
      }
      input.text-own {
        font-family: Overpass-Regular;
        display: block;
        box-sizing: border-box;
        width: 90%;
        margin: 10px auto;
        border-radius: 4px;
        border: 1px solid #bdbdbd;
        padding: 10px 10px;
        font-size: 14px;
        outline: none;
      }
      input.radio-own {
        display: inline-block;
        width: 6%;
      }

      .submit-btn-container {
        display: flex;
        align-content: flex-end;
        justify-content: flex-end;
        margin: 10px 5%;
        height: 40px;
        .next-button {
          display: grid;
          grid-template-columns: 30px auto;
          border: none;
          font-family: Maven-Regular;
          height: 40px;
          width: 230px;
          text-decoration: none;
          outline: none;
          color: white;
          background-size: 200% auto;
          border-radius: 5px;
          background-image: linear-gradient(
            60deg,
            #3d3393 0%,
            #2b76b9 25%,
            #2cacd1 55%,
            #1fbfa4 100%
          );
          transition: all 0.5s;
          .cloud-icon {
            width: 25px;
            align-self: center;
            justify-self: center;
          }
          .start-simulation {
            font-size: 0.9rem;
            align-self: center;
          }
        }
        .next-button:hover {
          cursor: pointer;
          background-position: 40% 0%;
        }
      }
    }
    .form-container::-webkit-scrollbar-track {
      background-color: #ffffff;
      border-bottom-right-radius: 6px;
    }

    .form-container::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    .form-container::-webkit-scrollbar-thumb {
      border-right: solid 1px white;
      border-left: solid 1px white;
      background-image: linear-gradient(
        0deg,
        #2b76b9 50%,
        #2cacd1 75%,
        #1fbfa4 100%
      );
      border-radius: 6px;
    }

    .form-container::-webkit-scrollbar-corner {
      background: rgba(0, 0, 0, 0);
    }
  }
}

@keyframes enter-bottom {
  from {
    transform: translateY(600px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

#step-3b {
  width: 70%;
  max-width: 500px;
  height: auto;
  padding-bottom: 10px;
  background: white;
  border-radius: 10px;
  // box-shadow: 4px 6px 10px 1px #808282;
  animation: enter-bottom 0.3s ease-in-out forwards;
  
  .modal-title {
    user-select: none;
    display: grid;
    justify-content: center;
    align-content: center;
    width: 100%;
    color: #2b76b9;
    height: 2.8rem;
    font-size: 1.3rem;
    text-align: center;
    border-bottom: solid 1px lightgray;
  }

  .path-selection {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 50px 150px 40px;
    justify-items: center;
    align-items: center;
    .single-path {
      #path-icon {
        cursor: pointer;
        width: 150px;
        height: 150px;
        animation: none;
        transition: 0.2s ease-in-out;
      }
      #path-icon:hover {
        transform: scale(1.2);
        // stroke-dasharray: 20;
        // stroke-dashoffset: 10;
        // animation: dash 2s ease-in-out forwards;
      }
    }
    .poly-path {
      #poly-icon {
        width: 100px;
        height: 100px;
      }
    }
    #button-span-container {
      .button-span {
        user-select: none;
        cursor: pointer;
        margin: 0px 7px;
        display: inline-block;
        transition: transform 0.2s ease-in-out;
      }
      .button-span:hover {
        transform: scale(1.2);
      }
    }
  }
}

@keyframes enter-bottom {
  from {
    transform: translateY(600px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

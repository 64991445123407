#whats-new {
  position: absolute;
  width: 90%;
  height: 90%;
  background-color: white;
  border-radius: 10px;
  z-index: 1;
  display: grid;
  grid-template-rows: 50px auto 50px;
  animation: fadeIn 0.4s linear forwards;

  box-sizing: border-box;
  border: solid 1px #cfcfcf;
  z-index: 11;
  .news-title {
    align-self: center;
    justify-self: center;
    font-size: 1.3rem;
  }
  .news-content {
    border-top: solid 1px lightgrey;
    border-bottom: solid 1px lightgrey;
    display: grid;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    p {
      margin: 10px 10px;
    }
    p:last-child {
      margin-bottom: 50px;
    }
    img {
      align-self: center;
      justify-self: center;
      max-width: 100%;
      max-height: 60vh;
    }
    .intro {
      margin-top: 50px;
      font-size: 1.1rem;
    }

    .feature-title {
      font-size: 1.1rem;
      font-weight: bold;
    }
    .feature-text {
      font-size: 1.1rem;
    }
  }

  .news-content::-webkit-scrollbar-track {
    background-color: #ffffff;
  }

  .news-content::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  .news-content::-webkit-scrollbar-thumb {
    border-right: solid 1px white;
    background-image: linear-gradient(
      0deg,
      #2b76b9 50%,
      #2cacd1 75%,
      #1fbfa4 100%
    );
    border-radius: 6px;
  }

  .news-content::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0);
  }

  .news-button {
    display: grid;
    align-self: center;
    justify-self: center;
    justify-content: center;
    align-content: center;
    border: none;
    font-family: Maven-Regular;
    font-size: 0.8rem;
    height: 30px;
    width: 150px;
    text-decoration: none;
    outline: none;
    color: white;
    background-size: 200% auto;
    border-radius: 5px;
    background-image: linear-gradient(
      60deg,
      #3d3393 0%,
      #2b76b9 25%,
      #2cacd1 55%,
      #1fbfa4 100%
    );
    transition: all 0.5s;
  }
  .news-button:hover {
    cursor: pointer;
    background-position: 30% 0%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

$primary-color: #3e7aff;
.side-panel {
  display: inline-grid;
  grid-template-rows: 100px 20px 45px auto;
  box-sizing: border-box;
  width: 20%;
  min-width: 315px;
  height: 100%;
  // background-color: #333; for dark theme
  background-color: white;
  backdrop-filter: blur(4px);
  z-index: 2;

  .loader {
    height: 32px;
    top: 0;
    left: 0;
    display: grid;
    justify-self: center;
    justify-items: center;
    align-items: center;
    align-self: center;
    .ball-pulse-sync div {
      height: 10px;
      width: 10px;
    }
    .ball-pulse-sync div:nth-child(1) {
      border-color: #2b76b9 !important;
      background-color: #2b76b9;
    }
    .ball-pulse-sync div:nth-child(2) {
      border-color: #2cacd1 !important;
      background-color: #2cacd1;
    }
    .ball-pulse-sync div:nth-child(3) {
      border-color: #1fbfa4 !important;
      background-color: #1fbfa4;
    }
  }

  .icefall-title {
    width: 100%;
    display: grid;
    user-select: none;
    align-items: center;
    justify-items: center;
    font-family: Overpass-Regular;
    font-size: 3rem;
    color: #2354d2;
    background-color: white;
    .tricer-logo {
      width: 200px;
    }
  }

  // KEEP EXAMPLE OF GRADIENT TEXT METHOD FOR THE SPAN
  //   span {
  //     background: -webkit-linear-gradient(0deg, #2b76b9 0%, #7bb4e6 90%);
  //     background-clip: text;
  //     color: transparent;
  //     align-self: center;
  //     justify-self: center;
  //     font-size: 1.1rem;
  //   }

  .new-project-btn {
    display: grid;
    align-self: center;
    justify-self: flex-end;
    margin-right: 20px;

    grid-template-columns: 25px auto;
    border: none;
    font-family: Maven-Regular;
    height: 30px;
    width: 75px;
    text-decoration: none;
    outline: none;
    color: white;
    background-size: 200% auto;
    border-radius: 5px;
    background-image: linear-gradient(
      60deg,
      #3d3393 0%,
      #2b76b9 25%,
      #2cacd1 55%,
      #1fbfa4 100%
    );
    transition: all 0.5s;
    .new-project-icon {
      width: 12px;
      align-self: center;
      justify-self: flex-end;
    }
    span {
      font-size: 1rem;
      align-self: center;
      justify-self: center;
    }
  }
  .new-project-btn:hover {
    cursor: pointer;
    background-position: 30% 0;
  }

  .projects-list {
    padding: 10px 10px;
    overflow-y: auto;
    // background-color: #333; for dark them
    background-color: white;
    img {
      cursor: pointer;
      justify-self: center;
      align-self: center;
      user-select: none;
    }
    .rotating-item {
      transition: all 0.05s ease-in;
    }
    .rotating-item:hover {
      border: 1px solid rgba(211, 211, 211, 0.671);
      padding: 3px;
      border-radius: 5px;
      background-color: rgba(211, 211, 211, 0.671);
    }

    // Here starts the tree
    .selected-tree {
      box-sizing: border-box;
      border: solid 1px rgb(156, 156, 156);
      border-radius: 5px;
      background-color: #f3f2ef;
    }
    .tree {
      box-sizing: border-box;
      display: grid;
      grid-template-columns: 20px auto;
      grid-template-rows: minMax(25px, auto) auto;
      padding: 5px 0;
      transition: background-color 0.4s;

      .project-level-grid {
        align-self: center;
        display: grid;
        min-height: 25px;
        grid-template-columns: 25px 1fr 60px;
        padding-right: 0;
        .project-icon {
          width: 17px;
          cursor: default;
        }
        span {
          margin-left: 5px;
          justify-self: left;
          align-self: center;
          user-select: none;
          cursor: pointer;
          max-width: calc(100% - 5px);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .button-container {
          display: flex;
          justify-content: flex-end;
          .more-container {
            display: grid;
            width: 25px;
            height: 25px;
            box-sizing: border-box;
            transition: background-color 0.3s;
            .moreIcon {
              align-self: center;
              width: 23px;
            }
          }
          .plus-container {
            display: grid;
            width: 25px;
            height: 25px;
            box-sizing: border-box;
            transition: background-color 0.3s;
            .plusIcon {
              align-self: center;
              width: 16px;
            }
          }
          .more-container:hover,
          .plus-container:hover {
            border: solid 1px rgb(211, 211, 211);
            border-radius: 3px;
            background-color: rgb(240, 240, 240);
          }
        }
      }

      .project-level-ul {
        grid-column: 1 / 3;
        user-select: none;
        margin: 0;
        padding-left: 20px;
        .aoi-list {
          user-select: none;
          font-size: 1rem;
          list-style-type: none;
          // animation-duration: 0.2s;
          // animation-timing-function: linear;
          // animation-direction: forwards;
          // animation-name: slide-in;
          .aoi-level-tree {
            align-self: center;
            display: grid;
            grid-template-columns: 20px 20px auto;
            grid-template-rows: minMax(25px, auto) auto;
            .aoi-icon {
              cursor: default;
            }
            .aoi-level-grid {
              display: grid;
              grid-template-columns: 1fr 60px;
              span {
                margin-left: 5px;
                justify-self: left;
                align-self: center;
                user-select: none;
                cursor: pointer;
                max-width: calc(100% - 5px);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              .button-container {
                display: flex;
                justify-content: flex-end;
                .more-container {
                  display: grid;
                  width: 25px;
                  height: 25px;
                  box-sizing: border-box;
                  transition: background-color 0.3s;
                  .moreIcon {
                    align-self: center;
                    width: 23px;
                  }
                }
                .plus-container {
                  display: grid;
                  width: 25px;
                  height: 25px;
                  box-sizing: border-box;
                  transition: background-color 0.3s;
                  .plusIcon {
                    align-self: center;
                    width: 16px;
                  }
                }
                .more-container:hover,
                .plus-container:hover {
                  border: solid 1px rgb(211, 211, 211);
                  border-radius: 3px;
                  background-color: rgb(240, 240, 240);
                }
              }
            }
            .aoi-level-ul {
              grid-column: 1 / 4;
              user-select: none;
              margin: 0;
              padding-left: 20px;
              list-style-type: none;
              .turbine-list {
                display: grid;
                width: 100%;
                user-select: none;
                font-size: 1rem;
                list-style-type: none;
                // animation-duration: 0.2s;
                // animation-timing-function: linear;
                // animation-direction: forwards;
                // animation-name: slide-in;
                .turbine-level-tree {
                  align-self: center;
                  display: grid;
                  grid-template-columns: 20px 20px auto;
                  grid-template-rows: minMax(25px, auto) auto;
                }
                .turbine-icon {
                  cursor: default;
                }
                .turbine-level-grid {
                  display: grid;
                  grid-template-columns: auto 1fr;
                  span {
                    margin-left: 5px;
                    justify-self: left;
                    align-self: center;
                    user-select: none;
                    cursor: pointer;
                    max-width: calc(100% - 5px);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                  .button-container {
                    display: flex;
                    justify-content: flex-end;
                    .more-container {
                      display: grid;
                      width: 25px;
                      height: 25px;
                      box-sizing: border-box;
                      transition: background-color 0.3s;
                      .moreIcon {
                        align-self: center;
                        width: 23px;
                      }
                    }
                    .plus-container {
                      display: grid;
                      width: 25px;
                      height: 25px;
                      box-sizing: border-box;
                      transition: background-color 0.3s;
                      .plusIcon {
                        align-self: center;
                        width: 16px;
                      }
                  }
                    .more-container:hover,
                    .plus-container:hover {
                      border: solid 1px rgb(211, 211, 211);
                      border-radius: 3px;
                      background-color: rgb(240, 240, 240);
                    }
                  }
                }
                .turbine-level-ul {
                  grid-column: 1 / 4;
                  user-select: none;
                  margin: 0;
                  padding-left: 20px;
                  list-style-type: none;
                  .config-list {
                    display: grid;
                    width: 100%;
                    user-select: none;
                    font-size: 1rem;
                    list-style-type: none;
                    // animation-duration: 0.2s;
                    // animation-timing-function: linear;
                    // animation-direction: forwards;
                    // animation-name: slide-in;
                    .config-level-tree {
                      align-self: center;
                      display: grid;
                      grid-template-columns: 20px 20px auto;
                      grid-template-rows: minMax(25px, auto) auto;
                      .configuration-icon {
                        cursor: default;
                      }
                      .config-level-grid {
                        display: grid;
                        grid-template-columns: auto 1fr;
                        span {
                          margin-left: 5px;
                          justify-self: left;
                          align-self: center;
                          user-select: none;
                          cursor: pointer;
                          max-width: calc(100% - 5px);
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                        }
                        .button-container {
                          display: flex;
                          justify-content: flex-end;
                          .more-container {
                            display: grid;
                            width: 25px;
                            height: 25px;
                            box-sizing: border-box;
                            transition: background-color 0.3s;
                            .moreIcon {
                              align-self: center;
                              width: 23px;
                            }
                          }
                          .plus-container {
                            display: grid;
                            width: 25px;
                            height: 25px;
                            box-sizing: border-box;
                            transition: background-color 0.3s;
                            .plusIcon {
                              align-self: center;
                              width: 16px;
                            }
                          }
                          .more-container:hover,
                          .plus-container:hover {
                            border: solid 1px rgb(211, 211, 211);
                            border-radius: 3px;
                            background-color: rgb(240, 240, 240);
                          }
                        }
                      }
                      .config-level-ul {
                        grid-column: 1 / 4;
                        user-select: none;
                        margin: 0;
                        padding-left: 20px;
                        list-style-type: none;
                        .risk-zone-list {
                          display: grid;
                          grid-template-columns: 5px 1fr;
                          margin-left: 5px;
                          .risk-zone-level-img {
                            cursor: default;
                          }
                          .risk-zone-level-grid {
                            display: grid;
                            grid-template-columns: auto 1fr;
                            margin-top: 1px;
                            margin-bottom: 1px;
                            span {
                              margin-left: 5px;
                              justify-self: left;
                              align-self: center;
                              user-select: none;
                              cursor: pointer;
                              max-width: calc(100% - 5px);
                              white-space: nowrap;
                              overflow: hidden;
                              text-overflow: ellipsis;
                            }

                            .more-container {
                              display: grid;
                              width: 25px;
                              height: 18px;
                              box-sizing: border-box;
                              transition: background-color 0.3s;
                              justify-self: flex-end;
                              .moreIcon {
                                align-self: center;
                                width: 17px;
                              }
                            }

                            .more-container:hover {
                              border: solid 1px rgb(211, 211, 211);
                              border-radius: 3px;
                              background-color: rgb(240, 240, 240);
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      span.projectname {
        font-size: 1rem;
      }
      span.aoiname {
        font-size: 1rem;
      }
      span.turbname {
        font-size: 0.95rem;
      }
      span.calcname {
        font-size: 0.9rem;
      }
      span.elname {
        font-size: 0.85rem;
      }

      .new-ic-aoi,
      .new-ic-turb,
      .new-ic-conf {
        margin-left: 5px;
        user-select: none;
        font-size: 0.85rem;
        color: #b9b7b7;
        // transition: all 0.2s;
        // animation-duration: 0.2s;
        // animation-timing-function: linear;
        // animation-direction: forwards;
        // animation-name: slide-in;
      }
      .new-ic-calc {
        margin-left: 4px;
        user-select: none;
        font-size: 0.85rem;
        color: #b9b7b7;
        // transition: all 0.2s;
        // animation-duration: 0.2s;
        // animation-timing-function: linear;
        // animation-direction: forwards;
        // animation-name: slide-in;
      }
      .new-ic-aoi:hover,
      .new-ic-turb:hover,
      .new-ic-conf:hover,
      .new-ic-calc:hover {
        cursor: pointer;
        color: rgb(130, 194, 255);
      }
    }
  }

  .projects-list::-webkit-scrollbar-track {
    background-color: #ffffff;
  }

  .projects-list::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  .projects-list::-webkit-scrollbar-thumb {
    border-right: solid 1px white;
    border-left: solid 1px white;
    background-image: linear-gradient(
      0deg,
      #2b76b9 50%,
      #2cacd1 75%,
      #1fbfa4 100%
    );
    border-radius: 6px;
  }

  .projects-list::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0);
  }
}

@keyframes slide-in {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes slide-out {
  from {
    opacity: 1;
    transform: translateX(0px);
  }
  to {
    opacity: 0;
    transform: translateX(-10px);
  }
}

@keyframes slide-left-right {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0px);
  }
}

@keyframes slide-right-left {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0px);
  }
}

#user-support {
    display: grid;
    #question-mark {
        user-select: none;
        position: relative;
        align-self: center;
        justify-self: center;
        left: 10px;
        width: 10px;
        border: solid 1px white;
        padding: 3px;
        border-radius: 5px;
        transition: transform 0.2s;
        &:hover {
            transform: scale(1.1);
            cursor: pointer;
        }
    }
    #user-support-modal {
        display: none;
        position: absolute;
        opacity: 0;
        right: 50px;
        top: 10px;
        width: 235px;
        font-size: 0.9rem;
        height: 100px;
        padding: 5px;
        overflow-wrap: break-word;
        overflow-y: auto;
        background-color: #2b76b9;
        color: white;
        border-radius: 5px;
        box-shadow: #1a4a74 1px 1px 3px 1px;
        z-index: 3;
        transition: all 0.5s;
        a {
            color: white;
            text-decoration: none;
        }
        a:hover {
            color: rgb(196, 196, 196);
        }
    }
    
    #user-support-modal:hover {
        opacity: 1;
        display: block;
    }
}

#question-mark:hover + #user-support-modal {
        display: block;
}



#user-support-modal::-webkit-scrollbar-track {
    background-color: #575656bd;
    border-radius: 5px;
  }

#user-support-modal::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    
  }

#user-support-modal::-webkit-scrollbar-thumb {
    background-color: rgb(183, 213, 238);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

#user-support-modal::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0);
  }
#step-1 {
  width: 50%;
  height: auto;
  background: white;
  border-radius: 6px;
  // box-shadow: 4px 6px 10px 1px #808282;
  animation: enter-bottom 0.3s ease-in-out forwards;

  .modal-title {
    user-select: none;
    display: grid;
    justify-content: center;
    align-content: center;
    width: 100%;
    color: #2b76b9;
    height: 2.8rem;
    font-size: 1.3rem;
    text-align: center;
  }

  form {
    margin-bottom: 38px;
    margin-top: 10px;
  }

  .submit-btn-container {
    position: absolute;
    right: 5%;
    bottom: 0px;
    margin: 0px 0px 10px 0px;
    display: flex;
    align-content: flex-end;
    justify-content: flex-end;
    .next-button {
      display: inline-block;
      border: none;
      font-family: Maven-Regular;
      height: 30px;
      width: 130px;
      text-decoration: none;
      outline: none;
      color: white;
      background-size: 200% auto;
      border-radius: 5px;
      background-image: linear-gradient(
        60deg,
        #3d3393 0%,
        #2b76b9 25%,
        #2cacd1 55%,
        #1fbfa4 100%
      );
      transition: all 0.5s;
    }
    .next-button:hover {
      cursor: pointer;
      background-position: 40% 0%;
    }
  }
}

@keyframes enter-bottom {
  from {
    transform: translateY(600px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

#user-notification {
  display: grid;
  #notification-icon {
    user-select: none;
    position: relative;
    align-self: center;
    justify-self: center;
    left: 10px;
    width: 19px;
    transition: transform 0.2s;
    &:hover {
      transform: scale(1.1);
      cursor: pointer;
    }
  }
}

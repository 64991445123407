.user-logout {
    display: grid;
   
    img {
        user-select: none;
        position: relative;
        align-self: center;
        justify-self: center;
        left: 5px;
        width: 20px;
        transition: transform 0.2s;
        &:hover {
            transform: scale(1.1);
            cursor: pointer;
        }
    }
}
#table-container {
  grid-row: 1 / 3;
  grid-column: 2 / 3;
  user-select: none;
  display: grid;
  width: 90%;
  height: 70vh;
  margin: 0 5%;
  overflow-y: auto;
  overflow-x: auto;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  animation: enter-bottom 0.3s ease-in-out forwards;

  #main-table {
    align-self: center;
    border-spacing: 1;
    border-collapse: collapse;
    background: white;
    overflow: auto;
    width: 100%;

    * {
      position: relative;
    }
    thead {
      top: 0;
    }
    thead tr th {
      position: sticky;
      top: 0;
      background-color: #6e6e6e;
      z-index: 1;
      width: 50px;
      color: white;
      padding-left: 8px;
    }
    thead tr {
      top: 0;
      height: 60px;
    }
    tbody tr {
      height: 50px;
    }
    tbody tr:nth-child(even) {
      background-color: #e0e0e0;
    }
    tbody tr:last-child {
      border: 0;
    }
    td {
      text-align: center;
    }
    th {
      text-align: center;
    }
  }
}

#table-container::-webkit-scrollbar-track {
  background-color: #ffffff;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
}

#table-container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

#table-container::-webkit-scrollbar-thumb {
  border-right: solid 1px white;
  border-left: solid 1px white;
  background-image: linear-gradient(
    0deg,
    #2b76b9 50%,
    #2cacd1 75%,
    #1fbfa4 100%
  );
  border-radius: 6px;
}

#table-container::-webkit-scrollbar-corner {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background: rgba(0, 0, 0, 0);
}

@keyframes enter-bottom {
  from {
    transform: translateY(600px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

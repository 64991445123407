#step-3-container {
  display: grid;
  height: 90vh;
  width: 100%;
  align-items: center;
  justify-items: center;
  #step-3 {
    width: 90%;
    height: auto;
    padding-bottom: 10px;
    background: white;
    border-radius: 6px;
    // box-shadow: 4px 6px 10px 1px #808282;
    animation: enter-bottom 0.3s ease-in-out forwards;
    margin: 0 5%;

    .modal-title {
      user-select: none;
      display: grid;
      justify-content: center;
      align-content: center;
      width: 100%;
      color: #2b76b9;
      min-height: 2.8rem;
      font-size: 1.3rem;
      text-align: center;
    }

    .date-text {
      color: #ababab;
      /* float: right; */
      /* width: 100%; */
      /* position: absolute; */
      /* right: 0px; */
      /* height: 2.8rem; */
      /* margin-right: 2%; */
      padding-right: 2%;
      display: grid;
      align-content: center;
      justify-content: right;
      border-bottom: solid 1px lightgray;
    }

    form {
      margin-top: 10px;
      .loader {
        height: 32px;
        top: 0;
        left: 0;
        display: grid;
        justify-self: center;
        justify-items: center;
        align-items: center;
        grid-column: 1 / 5;
        .ball-pulse-sync div {
          height: 10px;
          width: 10px;
        }
        .ball-pulse-sync div:nth-child(1) {
          border-color: #2b76b9 !important;
          background-color: #2b76b9;
        }
        .ball-pulse-sync div:nth-child(2) {
          border-color: #2cacd1 !important;
          background-color: #2cacd1;
        }
        .ball-pulse-sync div:nth-child(3) {
          border-color: #1fbfa4 !important;
          background-color: #1fbfa4;
        }
      }
    }
    #rotor-group {
      display: grid;
      width: 90%;
      margin: 0 auto 15px auto;
      height: 30px;
      grid-template-columns: 50% 50%;
      .radio-group {
        height: 20px;
        align-self: center;
        label {
          font-size: 0.9rem;
        }
      }
      .radio-label {
        bottom: 1px;
        position: relative;
      }
      .degree-input {
        margin: 0;
        height: 30px;
      }
    }
    #preventive-group {
      display: grid;
      width: 90%;
      height: 30px;
      margin: 0 auto 20px auto;
      grid-template-columns: 50% 50%;
      .radio-group {
        height: 20px;
        align-self: center;
        label {
          font-size: 0.9rem;
        }
      }
      .radio-label {
        bottom: 1px;
        position: relative;
      }
    }

    #download-upload-buttons-container {
      display: grid;
      width: 90%;
      margin: 0 auto;
      grid-template-columns: 45% 10% 45%;
      input[type='file'] {
        display: none;
      }
      .custom-file-upload {
        display: flex;
        width: 100px;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 6px 12px;
      }
      .custom-file-upload:hover {
        background-color: #f8f8f8;
        cursor: pointer;
      }

      .custom-file-upload[disabled] {
        cursor: default;
        background-color: #f8f8f8;
      }
      #uploadicon {
        width: 18px;
        margin-right: 5px;
      }

      .or-separator {
        align-self: center;
        justify-self: center;
      }

      .day-ahead {
        text-align: center;
        justify-self: flex-end;
        width: 120px;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 6px 12px;
      }
      .day-ahead:hover {
        background-color: #f8f8f8;
        cursor: pointer;
      }

      .day-ahead[disabled] {
        cursor: default;
        background-color: #f8f8f8;
      }
    }

    p {
      margin: 0 auto;
      width: 90%;
      padding: 0 27px;
    }
    p.error {
      margin: 0 auto;
      width: 90%;
      font-size: 0.7rem;
      color: red;
    }

    .degree-input {
      width: auto;
      max-width: 140px;
      margin-left: 10px;
      margin-right: 10px;
    }

    .submit-btn-container {
      position: relative;
      display: grid;
      justify-content: end;
      align-content: center;
      margin: 0 5% 10px 5%;
      right: 0;
      bottom: 0;

      .next-button {
        display: block;
        border: none;
        font-family: Maven-Regular;
        height: 30px;
        width: 80px;
        text-decoration: none;
        outline: none;
        color: white;
        background-size: 200% auto;
        border-radius: 5px;
        background-image: linear-gradient(
          60deg,
          #3d3393 0%,
          #2b76b9 25%,
          #2cacd1 55%,
          #1fbfa4 100%
        );
        transition: all 0.5s;
      }
      .next-button:hover {
        cursor: pointer;
        background-position: 40% 0;
      }
    }

    #download-template-container {
      user-select: none;
      margin: 10px 0 10px 5%;
      border: solid 1px #ccc;
      width: 160px;

      display: grid;
      align-content: center;
      justify-content: center;
      border-radius: 5px;
      a {
        width: 160px;
        text-align: center;
        padding: 6px 12px;
        user-select: none;
        text-decoration: none;
        color: black;
        cursor: pointer;
      }
    }
    #download-template-container:hover {
      background-color: #f8f8f8;
      cursor: pointer;
    }

    .download-pdf {
      align-self: center;
      justify-self: center;
      align-content: center;
      justify-content: center;
      margin: 0 auto 20px auto;
      display: grid;
      border: none;
      font-family: Maven-Regular;
      font-size: 0.9rem;
      height: 40px;
      width: 120px;
      text-decoration: none;
      outline: none;
      color: white;
      background-size: 200% auto;
      border-radius: 5px;
      background-image: linear-gradient(
        60deg,
        #3d3393 0%,
        #2b76b9 25%,
        #2cacd1 55%,
        #1fbfa4 100%
      );
      transition: all 0.5s;
    }
    .download-pdf:hover {
      background-position: 40% 0;
      cursor: pointer;
    }

    .loader-active {
      // top: 25px !important;
      height: 50px;
      // left: 50px !important;
      .loader-inner div {
        border: 1px solid #2354d2 !important;
      }
    }
  }

  #step-3::-webkit-scrollbar-track {
    background-color: #575656c4;
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
  }

  #step-3::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  #step-3::-webkit-scrollbar-thumb {
    background-color: rgb(183, 213, 238);
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
  }

  #step-3::-webkit-scrollbar-corner {
    background: rgba(255, 255, 255, 0);
  }

  .cloning-status-div {
    display: grid;
    grid-template-rows: 40px auto;
    grid-column: span 2;
    background-color: white;
    animation: enter-bottom 0.4s ease-in-out forwards;
    height: 120px;
    width: 340px;
    padding: 10px;
    border-radius: 10px;

    .duplicating-title {
      align-self: center;
      justify-self: center;
    }

    .duplicating-eta {
      align-self: center;
      justify-self: center;
    }

    .loader {
      height: 32px;
      top: 0;
      left: 0;
      display: grid;
      justify-self: center;
      justify-items: center;
      align-items: center;
      align-self: center;
      grid-column: 1 / 3;
      .ball-pulse-sync div {
        height: 10px;
        width: 10px;
      }
      .ball-pulse-sync div:nth-child(1) {
        border-color: #2b76b9 !important;
        background-color: #2b76b9;
      }
      .ball-pulse-sync div:nth-child(2) {
        border-color: #2cacd1 !important;
        background-color: #2cacd1;
      }
      .ball-pulse-sync div:nth-child(3) {
        border-color: #1fbfa4 !important;
        background-color: #1fbfa4;
      }
    }
  }

  .risk-zones-checkbox-list {
    width: 90%;
    min-height: 380px;
    height: 50%;
    padding-bottom: 10px;
    background: white;
    border-radius: 10px;
    // box-shadow: 4px 6px 10px 1px #808282;
    animation: enter-bottom 0.3s ease-in-out forwards;
    margin: 0 5%;

    span {
      font-family: Maven-Regular;
    }

    .modal-title {
      user-select: none;
      display: grid;
      justify-content: center;
      align-content: center;
      width: 100%;
      color: #2b76b9;
      height: 2.8rem;
      font-size: 1.3rem;
      text-align: center;
    }
    .check-all {
      display: grid;
      align-content: center;
      justify-content: center;
    }

    .risk-checklist {
      margin-right: 10px;
      margin-left: 10px;
      border: solid 1px lightgrey;
      border-radius: 5px;
      padding-left: 10px;
      padding-right: 10px;
      display: flex;
      flex-direction: column;
      height: calc(100% - 2.8rem - 42px);
      overflow-y: auto;
    }
    .risk-checklist::-webkit-scrollbar-track {
      background-color: #ffffff;
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;
    }

    .risk-checklist::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    .risk-checklist::-webkit-scrollbar-thumb {
      border-right: solid 1px white;
      border-left: solid 1px white;
      background-image: linear-gradient(
        0deg,
        #2b76b9 50%,
        #2cacd1 75%,
        #1fbfa4 100%
      );
      border-radius: 6px;
    }

    .risk-checklist::-webkit-scrollbar-corner {
      background: rgba(0, 0, 0, 0);
    }
    .form-container::-webkit-scrollbar-track {
      background-color: #ffffff;
      border-bottom-right-radius: 6px;
    }

    .form-container::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    .form-container::-webkit-scrollbar-thumb {
      border-right: solid 1px white;
      border-left: solid 1px white;
      background-image: linear-gradient(
        0deg,
        #2b76b9 50%,
        #2cacd1 75%,
        #1fbfa4 100%
      );
      border-radius: 6px;
    }
  }
}

@keyframes enter-bottom {
  from {
    transform: translateY(600px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}
